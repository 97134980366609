@font-face {
    font-family: "Raleway";
    src: local("Raleway"),
     url("./fonts/Raleway/Raleway-Light.ttf") format("truetype");
    font-weight: 500;
}
@font-face {
    font-family: "Raleway";
    src: local("Raleway"),
     url("./fonts/Raleway/Raleway-Light.ttf") format("truetype");
    font-weight: 400;
}